import { createStore } from "vuex";

export default createStore({
  state: {
    user: null,
    token: null,
    displayRedirectDossier: false,
    isWorkingCB: false,
    isWorkingCPF: false,
    isWorkingAutre: false,
    messagePaiement: "",
    adresseFacturation: null,
    fonctionnalite: null,
    paiement: null,
    moniteurs: null,
    utilisateurs: null,
    lieus: null,
    lieusRdv: null,
    lieusExam: null,
    elevesAdmin: null,
    permis: null,
    vehicules: null,
    statutSocial: null,
    lycees: null,
    dossier: null,
    typePieceJustificative: null,
    theme: null,
    sousTheme: null,
    serie: null,
    competences: null,
    objectifs: null,
    sousobjectifs: null,
    journeesReservation: null,
    descriptionPiece: null,
    studentDossier: null,
    studentCode: null,
    allElevesPage : null,
    lyceen : false
  },
  getters: {
    redirectDossierNeed(state) {
      if (state.user.dossier === null) {
        return true;
      }
      return false;
    },

    adresseFacturation(state) {
      return state.adresseFacturation;
    },

    fonctionnalite(state) {
      return state.fonctionnalite;
    },

    paiement(state) {
      return state.paiement;
    },

    permis(state) {
      if (state.elevesDossier !== undefined) {
        return state.elevesDossier.permis;
      }
      return state.permis;
    },

    statutSocial(state) {
      if (state.elevesDossier !== undefined) {
        return state.elevesDossier.statutSocial;
      }
      return state.statutSocial;
    },

    lycees(state) {
      if (state.elevesDossier !== undefined) {
        return state.elevesDossier.lycee;
      }
      return state.lycees;
    },

    dossier(state) {
      return state.dossier;
    },

    typePieceJustificative(state) {
      return state.typePieceJustificative;
    },

    theme(state) {
      return state.theme;
    },

    sousTheme(state) {
      return state.sousTheme;
    },

    serie(state) {
      return state.serie;
    },

    competences(state) {
      return state.competences;
    },
    lieus(state) {
      return state.lieus;
    },
    lieusRdv(state) {
      return state.lieusRdv;
    },
    lieusExam(state) {
      return state.lieusExam;
    },
    vehicules(state) {
      return state.vehicules;
    },
    journeesReservation(state) {
      return state.journeesReservation;
    },
    elevesAdmin(state) {
      return state.elevesAdmin;
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      localStorage.setItem("user_info", JSON.stringify(user));
    },

    setLyceen(state, lyceen) {
      state.lyceen = lyceen;
    },

    setStudentDossier(state, dossier) {
      state.studentDossier = dossier;
    },

    setAllElevePage(state, allElevesPage) {
      state.allElevesPage = allElevesPage;
    },

    setStudentCode(state, code) {
      state.studentCode = code;
    },


    setToken(state, token) {
      state.token = token;
      localStorage.setItem("user_access", JSON.stringify(token));
    },

    setIsWorkingCB(state, isWorkingCB) {
      state.isWorkingCB = isWorkingCB;
    },

    setIsWorkingCPF(state, isWorkingCPF) {
      state.isWorkingCPF = isWorkingCPF;
    },

    setIsWorkingAutre(state, isWorkingAutre) {
      state.isWorkingAutre = isWorkingAutre;
    },

    setMessagePaiement(state, messagePaiement) {
      state.messagePaiement = messagePaiement;
    },

    setAdresseFacturation(state, adresseFacturation) {
      state.adresseFacturation = adresseFacturation;
    },

    setFonctionnalite(state, fonctionnalite) {
      state.fonctionnalite = fonctionnalite;
    },

    setPaiement(state, paiement) {
      state.paiement = paiement;
    },

    setDossier(state, dossier) {
      state.dossier = dossier;
    },

    setPermis(state, permis) {
      state.permis = permis;
    },

    setStatutSocial(state, statutSocial) {
      state.statutSocial = statutSocial;
    },

    setLycees(state, lycees) {
      state.lycees = lycees;
    },

    setTypePieceJustificative(state, typePieceJustificative) {
      state.typePieceJustificative = typePieceJustificative;
    },

    setMoniteurs(state, moniteurs) {
      state.moniteurs = moniteurs;
    },

    setlieus(state, lieus) {
      state.lieus = lieus;
    },

    setvehicules(state, vehicules) {
      state.vehicules = vehicules;
    },

    setutilisateurs(state, utilisateurs) {
      state.utilisateurs = utilisateurs;
    },

    setelevesAgence(state, Elevesagence) {
      state.Elevesagence = Elevesagence;
    },

    setmoniteursAgence(state, MoniteursAgence) {
      state.MoniteursAgence = MoniteursAgence;
    },

    setelevesArchive(state, EleveArchive) {
      state.EleveArchive = EleveArchive;
    },

    setelevesAdmin(state, ElevesAdmin) {
      state.ElevesAdmin = ElevesAdmin;
    },

    setElevesDossier(state, elevesDossier) {
      state.elevesDossier = elevesDossier;
    },

    setTheme(state, theme) {
      state.theme = theme;
    },

    setSousTheme(state, sousTheme) {
      state.sousTheme = sousTheme;
    },

    setSerie(state, serie) {
      state.serie = serie;
    },

    setCompetences(state, Competences) {
      state.Competences = Competences;
    },

    setlieusRdv(state, lieusRdv) {
      state.lieusRdv = lieusRdv.filter(
        (l) => l.TypeLieu === "/api/type_lieus/1"
      );
    },

    setlieusExam(state, lieusExam) {
      state.lieusExam = lieusExam.filter(
        (l) => l.TypeLieu === "/api/type_lieus/2"
      );
    },

    setJourneesReservation(state, journeesReservation) {
      state.journeesReservation = journeesReservation;
    },
  },
  actions: {
    startSaveBouton({ commit }, mode) {
      commit("setIsWorkingCB", null);
      commit("setIsWorkingCPF", null);
      commit("setIsWorkingAutre", null);
      if (mode === "cb") {
        commit("setIsWorkingCB", true);
      }
      if (mode === "cpf") {
        commit("setIsWorkingCPF", true);
      }
      if (mode === "autre") {
        commit("setIsWorkingAutre", true);
      }
    },

    endSaveBouton(
      { commit, state },
      { mode, fonctionnalite, router, montantParam }
    ) {
      commit("setIsWorkingCB", null);
      if (mode == "cb") {
        router.push({
          name: "Paiement",
          params: { fonctionnalite: fonctionnalite, montant: montantParam },
        });
      } else {
        if (state.user.agence != null) {
          window.api
            .get("/agences/" + state.user.agence.id, {
              headers: {
                Authorization: "Bearer " + state.token,
              },
            })
            .then((response) => {
              if (mode !== "admin" && mode !== "neph") {
                commit(
                  "setMessagePaiement",
                  "Merci de nous contacter au " +
                    response.data.telephone +
                    " ou par mail " +
                    response.data.mail +
                    "."
                );
              }
              commit("setIsWorkingCB", false);
              commit("setIsWorkingCPF", false);
              commit("setIsWorkingAutre", false);
              if (mode === "cb") {
                commit("setIsWorkingCB", null);
              }
              if (mode === "cpf") {
                commit("setIsWorkingCPF", null);
              }
              if (mode === "autre") {
                commit("setIsWorkingAutre", null);
              }
              if (mode === "neph") {
                router.push({ name: "Home" });
              }
            })
            .catch((error) => {
              commit("setIsWorkingCB", false);
              commit("setIsWorkingCPF", false);
              commit("setIsWorkingAutre", false);
              if (mode === "cb") {
                commit("setIsWorkingCB", null);
              }
              if (mode === "cpf") {
                commit("setIsWorkingCPF", null);
              }
              if (mode === "autre") {
                commit("setIsWorkingAutre", null);
              }
              console.log(error);
            });
        } else {
          window.api
            .get("/dossier/admin/", {
              headers: {
                Authorization: "Bearer " + state.token,
              },
            })
            .then((response) => {
              if (mode !== "admin" && mode !== "neph") {
                commit(
                  "setMessagePaiement",
                  "Merci de nous contacter au " +
                    response.data.telephone +
                    " ou par mail " +
                    response.data.email +
                    "."
                );
              }
              commit("setIsWorkingCB", false);
              commit("setIsWorkingCPF", false);
              commit("setIsWorkingAutre", false);
              if (mode === "cb") {
                commit("setIsWorkingCB", null);
              }
              if (mode === "cpf") {
                commit("setIsWorkingCPF", null);
              }
              if (mode === "autre") {
                commit("setIsWorkingAutre", null);
              }
              if (mode === "neph") {
                router.push({ name: "Home" });
              }
            })
            .catch((error) => {
              commit("setIsWorkingCB", false);
              commit("setIsWorkingCPF", false);
              commit("setIsWorkingAutre", false);
              if (mode === "cb") {
                commit("setIsWorkingCB", null);
              }
              if (mode === "cpf") {
                commit("setIsWorkingCPF", null);
              }
              if (mode === "autre") {
                commit("setIsWorkingAutre", null);
              }
              console.log(error);
            });
        }
      }
    },

    getAdresseFacturation({ commit }) {
      if (this.state.adresseFacturation === null) {
        if (this.state.user.adresseFacturation !== null) {
          window.api
            .get(
              "/adresse_facturations/" + this.state.user.adresseFacturation.id,
              {
                headers: {
                  Authorization: "Bearer " + this.state.token,
                },
              }
            )
            .then((response) => {
              commit("setAdresseFacturation", response.data);
            })
            .catch((error) => {
              console.log(error.response.data.message);
            });
        } else {
          commit("setAdresseFacturation", {
            adresse: "",
            ville: "",
            cp: "",
          });
        }
      }
    },

    getFonctionnalite({ commit }) {
      if (this.state.fonctionnalite === null) {
        window.api
          .get("/fonctionnalites", {
            headers: {
              Authorization: "Bearer " + this.state.token,
            },
          })
          .then((response) => {
            commit("setFonctionnalite", response.data["hydra:member"]);
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      }
    },

    getPaiement({ commit }) {
      commit("setPaiement", null);
      window.api
        .get("/utilisateur/" + this.state.user.id + "/paiement", {
          headers: {
            Authorization: "Bearer " + this.state.token,
          },
        })
        .then((response) => {
          commit("setPaiement", response.data["Paiement"]);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    getDossier({ commit }) {
      if (this.state.user.dossier !== null) {
        window.api
          .get("/dossiers/" + this.state.user.dossier.id, {
            headers: {
              Authorization: "Bearer " + this.state.token,
            },
          })
          .then((response) => {
            commit("setDossier", response.data);
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      } else {
        commit("setDossier", {
          nomUsage: "",
          autresPrenom: "",
          telParent: "",
          mailParent: "",
          adresse: "",
          codePostal: "",
          ville: "",
          dateNaissance: "",
          paysNaissance: "",
          depNaissance: "",
          villeNaissance: "",
          nationaliteFR: null,
          parlerFRCouramment: null,
          vehiculeAmenage: null,
          lunettes: null,
          permis: null,
          faitSuite: null,
          motifAlcoolStup: null,
          prevoitAac: null,
          social: null,
          metier: "",
          lycee: null,
          autreLycee: "",
          numNeph: "",
          typeFabrication: null,
        });
      }
    },

    getPermis({ commit }) {
      if (this.state.permis === null) {
        window.api
          .get("/permis", {
            headers: {
              Authorization: "Bearer " + this.state.token,
            },
          })
          .then((response) => {
            commit("setPermis", response.data["hydra:member"]);
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      }
    },

    getStatutSocial({ commit }) {
      if (this.state.statutSocial === null) {
        window.api
          .get("/statut_socials", {
            headers: {
              Authorization: "Bearer " + this.state.token,
            },
          })
          .then((response) => {
            commit("setStatutSocial", response.data["hydra:member"]);
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      }
    },

    getLycees({ commit }) {
      window.api
        .get("/lycees", {
          headers: {
            Authorization: "Bearer " + this.state.token,
          },
        })
        .then((response) => {
          commit("setLycees", response.data["hydra:member"]);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    getTypePieceJustificative({ commit }) {
      window.api
        .get("/type_piece_justificative", {
          headers: {
            Authorization: "Bearer " + this.state.token,
          },
        })
        .then((response) => {
          commit("setTypePieceJustificative", response.data["hydra:member"]);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    getMoniteurs({ commit }) {
      window.api
        .get("/allmoniteurs", {
          headers: {
            Authorization: "Bearer " + this.state.token,
          },
        })
        .then((response) => {
          commit("setMoniteurs", response.data.Moniteur);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    getLieus({ commit }) {
      window.api
        .get("/lieus", {
          headers: {
            Authorization: "Bearer " + this.state.token,
          },
        })
        .then((response) => {
          commit("setlieus", response.data["hydra:member"]);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    getVehicules({ commit }) {
      window.api
        .get("/allvehicules", {
          headers: {
            Authorization: "Bearer " + this.state.token,
          },
        })
        .then((response) => {
          commit("setvehicules", response.data.Vehicule);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    getUtilisateurs({ commit }) {
      window.api
        .get("/utilisateur", {
          headers: {
            Authorization: "Bearer " + this.state.token,
          },
        })
        .then((response) => {
          commit("setutilisateurs", response.data.utilisateurs);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    getEleveAgence({ commit }) {
      window.api
        .get("/eleves/agence/" + this.state.user.agence.id, {
          headers: {
            Authorization: "Bearer " + this.state.token,
          },
        })
        .then((response) => {
          commit("setelevesAgence", response.data.utilisateurs);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    getMoniteurAgence({ commit }) {
      window.api
        .get("/moniteurs/agence/" + this.state.user.agence.id, {
          headers: {
            Authorization: "Bearer " + this.state.token,
          },
        })
        .then((response) => {
          commit("setmoniteursAgence", response.data.Moniteur);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    getEleveArchive({ commit }) {
      window.api
        .get("/elevesArchive/agence/" + this.state.user.agence.id, {
          headers: {
            Authorization: "Bearer " + this.state.token,
          },
        })
        .then((response) => {
          commit("setelevesArchive", response.data.utilisateurs);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    getTheme({ commit }) {
      if (this.state.theme === null) {
        window.api
          .get("/themes", {
            headers: {
              Authorization: "Bearer " + this.state.token,
            },
          })
          .then((response) => {
            commit("setTheme", response.data["hydra:member"]);
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      }
    },

    getSousTheme({ commit }) {
      if (this.state.sousTheme === null) {
        window.api
          .get("/sous_themes", {
            headers: {
              Authorization: "Bearer " + this.state.token,
            },
          })
          .then((response) => {
            commit("setSousTheme", response.data["hydra:member"]);
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      }
    },

    getSerie({ commit }) {
      if (this.state.serie === null) {
        window.api
          .get("/series", {
            headers: {
              Authorization: "Bearer " + this.state.token,
            },
          })
          .then((response) => {
            commit("setSerie", response.data["hydra:member"]);
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      }
    },

    getEleveAdmin({ commit }) {
      window.api
        .get("/eleves/Partieadmin", {
          headers: {
            Authorization: "Bearer " + this.state.token,
          },
        })
        .then((response) => {
          console.log("test", response.data);
          commit("setelevesAdmin", response.data.utilisateurs);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    getElevesDossier({ commit }) {
      window.api
        .get("/dossier/eleves", {
          headers: {
            Authorization: "Bearer " + this.state.token,
          },
        })
        .then((response) => {
          commit("setElevesDossier", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCompetences({ commit }) {
      window.api
        .get("/competences", {
          headers: {
            Authorization: "Bearer " + this.state.token,
          },
        })
        .then((response) => {
          commit("setCompetences", response.data["hydra:member"]);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    getLieusRdv({ commit }) {
      window.api
        .get("/lieus", {
          headers: {
            Authorization: "Bearer " + this.state.token,
          },
        })
        .then((response) => {
          commit("setlieusRdv", response.data["hydra:member"]);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    getLieusExam({ commit }) {
      window.api
        .get("/lieus", {
          headers: {
            Authorization: "Bearer " + this.state.token,
          },
        })
        .then((response) => {
          commit("setlieusExam", response.data["hydra:member"]);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },

    getJourneesReservation({ commit }) {
      window.api
        .get("/journee_reservations", {
          headers: {
            Authorization: "Bearer " + this.state.token,
          },
        })
        .then((response) => {
          commit("setJourneesReservation", response.data["hydra:member"]);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
  },
  modules: {},
});
